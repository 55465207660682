<template>
  <div>
    <v-list three-line>
      <v-list-item
        v-for="script in scripts"
        :key="script._id"
      >
        <v-list-item-content>
          <v-list-item-title>
            <strong>{{ script.name }}</strong>
          </v-list-item-title>
          <v-list-item-subtitle>{{ script.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            color="success"
            dark
            elevation="0"
            @click="modalActiveForScript = script"
          >
            Execute
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-dialog
      :value="Boolean(modalActiveForScript)"
      width="600"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      @click:outside="() => modalActiveForScript = null"
    >
      <v-card v-if="modalActiveForScript">
        <v-card-title>
          Execute {{ modalActiveForScript.name }}
        </v-card-title>
        <v-card-text>
          <div
            v-for="(option, key) in modalActiveForScript.args"
            :key="key"
            class="mb-4"
          >
            <div
              v-if="option.type === 'date'"
            >
              <label class="subtitle-1 d-block mb-2">{{ option.label }}</label>
              <v-date-picker
                v-model="optionValues[key]"
                :landscape="$vuetify.breakpoint.smAndUp"
                :max="new Date().toISOString().substr(0, 10)"
                color="shamrock"
              />
            </div>
            <v-text-field
              v-else
              v-model="optionValues[key]"
              :label="option.label"
              :placeholder="option.placeholder"
            />
          </div>
          <v-alert
            v-if="executionError"
            type="error"
            class="mb-0"
          >
            {{ executionError }}
          </v-alert>
          <v-alert
            v-if="executionResponse"
            type="success"
            class="mb-0"
          >
            <strong>Script complete:</strong> {{ executionResponse }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn
            class="mx-3 my-3"
            color="shamrock"
            :dark="!executionResponse"
            elevation="0"
            :loading="isExecuting"
            :disabled="executionResponse"
            @click="onFormSubmit"
          >
            Execute now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import useFetch from '@/hooks/useFetch'
import useAxios from '@/hooks/useAxios'
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'Projects',
  setup () {
    const { data: scripts } = useFetch('scripts')
    const axios = useAxios()

    const modalActiveForScript = ref(null)

    const isExecuting = ref(false)
    const executionError = ref(null)
    const executionResponse = ref(null)

    watch(modalActiveForScript, () => {
      executionError.value = null
      executionResponse.value = null
    })

    const optionValues = reactive({})

    const onFormSubmit = async () => {
      isExecuting.value = true

      try {
        const { data } = await axios.post(`/scripts/${modalActiveForScript.value.shortName}`, {
          ...optionValues
        })
        executionError.value = null
        executionResponse.value = data
      } catch (err) {
        executionError.value = err.response.data.message || err.message
      }

      isExecuting.value = false
    }

    return {
      isExecuting,
      executionError,
      executionResponse,
      scripts,
      optionValues,
      onFormSubmit,
      modalActiveForScript
    }
  }
})
</script>

<style>

</style>
